import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  components: {
    AppTitle,
  },
  mixins: [HeadingLevel],
  data() {
    return {
      items: typeof this.$t('ReasonsWhy.items') === 'object' ? this.$t('ReasonsWhy.items') : null,
      icons: {
        child: require('@icon/child.svg?raw'),
        eco: require('@icon/eco.svg?raw'),
        heart: require('@icon/heart.svg?raw'),
      },
    }
  },
}
